import { Component, Watch, Vue, Ref } from 'vue-property-decorator'
import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'
import { mapState } from 'vuex'
import Container from 'typedi'
import Notification from '@/modules/common/services/notification.service'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import { ValidationObserver } from 'vee-validate'

@Component({
  name: 'GtrEventNewScheduledEmailView',
  computed: {
    ...mapState('email', ['emails'])
  }
})
export default class GtrEventNewScheduledEmailView extends GtrSuper {
  @Ref()
  readonly observer!: InstanceType<typeof ValidationObserver>;

  eventFields!: Record<string, any>

  data () {
    return {
      loading: false,
      submitting: false,
      showRecipientsButton: false,
      emailCreated: false,
      criteria: false,
      scheduled_email_uuid: null,
      emailsToSchedule: [],
      createScheduledEmail: {
        email: null,
        event_uuid: this.$route.params.event_uuid,
        company_uuid: this.$route.params.uuid,
        datetime: null,
        user_type: 'Participant',
        criteria: {
          visible: false,
          global_type: '*',
          type: '*',
          field: '',
          operator: '',
          value: '',
          group_operator: '',
          group_items: []
        }
      }
    }
  }

  async mounted () {
    await this.fetchData()
  }

  handleChangeUseCriteria (checked: boolean) {
    this.$data.scheduledEmail.criteria = {
      visible: checked,
      global_type: '*',
      type: '*',
      field: '',
      operator: '',
      value: '',
      group_operator: '',
      group_items: []
    }
  }

  convertOldShowIfToNewFormat (payload: any) {
    const mergedShowIfObject = Object.assign({}, {
      global_type: '*',
      visible: false,
      type: '*',
      field: '',
      operator: '',
      value: '',
      group_operator: '',
      group_items: []
    }, payload)

    if (payload?.type === 'grouping') {
      mergedShowIfObject.global_type = 'multiple'
    } else if (['field_criteria', 'option_criteria'].includes(payload?.type)) {
      mergedShowIfObject.global_type = 'single'
    }
    return mergedShowIfObject
  }

  @Watch('emails', { immediate: true })
  onEmailsChange (emails: any[]) {
    emails.map((email: any) => {
      const name = this.handleEmailName(email.key)
      this.$data.emailsToSchedule.push({
        text: name,
        value: email.key
      })
    })
  }

  async submit () {
    const form = (this.$refs.createScheduledEmailForm as HTMLFormElement)
    if (form.validate()) {
      try {
        this.$data.submitting = true
        const email: any = {
          email_name: this.$data.createScheduledEmail.email,
          criteria: this.$data.createScheduledEmail.criteria,
          send_time: this.convertDatetimeToLocalTimezone(this.$data.createScheduledEmail.datetime.toLocaleString()),
          user_type: this.$data.createScheduledEmail.user_type,
          cc: '',
          bcc: ''
        }
        this.$data.scheduled_email_uuid = (await this.$store.dispatch('email/createScheduledEmail', {
          event_uuid: this.$route.params.event_uuid,
          data: email
        })).data.uuid
        Container.get(Notification).success('Email successfully created.')
        this.$bus.$emit('gtr.email_created')
        this.$data.showRecipientsButton = true
        this.$data.emailCreated = true
      } catch (error) {
        Container.get(ErrorHandlerService).error(error)
      } finally {
        this.$data.submitting = false
      }
    }
  }

  private async fetchData () {
    if (this.$store.state.email.emails.length > 0) {
      this.$store.state.email.emails.map((email: any) => {
        const name = this.handleEmailName(email.key)
        this.$data.emailsToSchedule.push({
          text: name,
          value: email.key
        })
      })
    } else {
      await this.$store.dispatch('email/fetchEmails', this.$route.params.event_uuid)
    }
  }

  get scheduledEmailChoices (): any[] {
    const filteredEmails = this.$data.emailsToSchedule.filter((email) => email.value !== 'admin_cancellation_notification' && email.value !== 'admin_registration_notification')
    return filteredEmails.sort((a, b) => a.text > b.text)
  }

  clearForm () {
    this.$data.createScheduledEmail = {
      email: null,
      event_uuid: this.$route.params.event_uuid,
      company_uuid: this.$route.params.uuid,
      datetime: null,
      user_type: 'Participant'
    }
    this.observer.reset()
    this.$data.createScheduledEmail.criteria = {
      visible: false,
      global_type: '*',
      type: '*',
      field: '',
      operator: '',
      value: '',
      group_operator: '',
      group_items: []
    }
  }
}
